<template>
	<b-modal size="xl" ref="modal" @hidden="clear">
		<template slot="modal-header" slot-scope="{ close }">
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
			<h2 class="mx-auto my-auto">{{ $t('monte.ajout_contract_lot') }}</h2>
		</template>
		<template slot="default">
			<SeasonInput v-model="season" />

			<div class="row mt-2">
				<div class="col-12">
					<div class="form-group">
						<label>{{ $t('monte.mare') }}</label>
						<SearchJument
							:horse_selected.sync="jument_selected"
							:juments="juments_options"
						/>
					</div>
				</div>

				<div class="col-12 border-bottom py-2" v-for="(jument, index) in juments_contract" :key="jument.mare_id">
					<div class="row">
						<div class="col">
							<h3>{{ jument.mare_name }}</h3>
						</div>
						<div class="col-auto">
				            <b-button @click="deleteJument(index)" variant="secondary">
								<font-awesome-icon :icon="['fal', 'trash-alt']" />
							</b-button>
						</div>
					</div>

					<Etalonv2
						:stallion_id="jument.contract_config.contractconfig_horse"
						:season.sync="season"
						:contract_config.sync="jument.contract_config"
						:nb_vs="jument.nb_vs"
						:nb_p="jument.nb_p"
						v-model="jument.stallion"
						@change="loadStallionInfo(index, jument.contract_config)"
					>
					</Etalonv2>

					<Modelev2
						v-if="jument.contract_config && jument.contract_config.contractconfig_id"
						:contractconfig_id="jument.contract_config.contractconfig_id"
						:stallion_models="jument.stallion_models"
						v-model="jument.modele"
					></Modelev2>

					<TypeMontev2
						v-if="jument.contract_config && jument.contract_config.contractconfig_id"
						:contractconfig_id="jument.contract_config.contractconfig_id"
						:type_montes_stallion="jument.type_montes_stallion"
						v-model="jument.type_monte"
					></TypeMontev2>
				</div>

				<div class="col-12">
					<ChangementCMEPv2
						:tiers_tab.sync="all_tiers"
						:cmep.sync="cmep"
					>
					</ChangementCMEPv2>
				</div>
			</div>
		</template>

		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="checkForm()" :disabled="processing">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				{{ $t('global.ajouter') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import Contract from "@/mixins/Contract.js"
	import Tiers from "@/mixins/Tiers.js"

	export default {
		name: 'ModalAddContractLot',
		mixins: [Contract, Tiers],
		props: {
			tiers_id: {
				type: Number,
				default: () => ( 0 )
			},
		},
		data () {
			return {
				season: null,
				juments_options: [],
				jument_selected: [],
				juments_contract: [],
				processing: false,
				stallion: {},
				cmep: {},
				all_tiers: []
			}
		},

		methods: {
			async openModal() {
				this.$refs.modal.show()

				const t = await this.loadHorse(this.tiers_id)
				const tiershorse_juments = t.filter(th => {
					return th.horse.horse_sexe == 'F'
				})

				this.juments_options = tiershorse_juments.map(th => {
					return th.horse
				})

				this.all_tiers = await this.loadTiers()
			},

			async loadStallionInfo(index, cc) {
				this.juments_contract[index].contract_config = cc
				await this.getContractConfigInfos(this.juments_contract[index].contract_config.contractconfig_id)
				.then((res) => {
					this.juments_contract[index].nb_vs = res.count.nb_vs
					this.juments_contract[index].nb_p = res.count.nb_p
					this.juments_contract[index].stallion_models = res.models
					this.juments_contract[index].type_montes_stallion = res.type_monte
				})

				// permet de refresh la vue sinon les éléments ne se mettent pas à jour
				this.juments_contract.push({
					mare_id: 0,
					mare_name: '',
					stallion: {},
					contract_config: {},
					modele: null,
					type_monte: {}
				})
				this.$nextTick(() => {
					this.juments_contract.pop()
				})
			},

			clear() {
				this.juments_contract = []
			},

			async checkForm() {
				this.processing = true

				const infos = this.juments_contract.map(jument => {
					return {
						mare_id: jument.mare_id,
						contractconfig_id: jument.contract_config.contractconfig_id,
						modele_id: jument.modele.model_id,
						typemonte_id: jument.type_monte.id,
						cmep_id: this.cmep.tiers_id,
						tiers_id: this.tiers_id
					}
				})

				await this.createContractLot(infos)
				await this.$sync.force(true, true)
				EventBus.$on('Indexation::stop', () => {
					this.processing = false

					this.$refs.modal.hide()
					this.$emit('submit')
					EventBus.$off('Indexation::stop');
				})
			},

			deleteJument(index) {
				this.juments_contract.splice(index, 1)
			}
		},
		watch: {
			'jument_selected': {
				deep: true,
				handler(val) {
					this.juments_contract.push({
						mare_id: val.horse_id,
						mare_name: val.horse_nom,
						stallion: {},
						contract_config: {},
						modele: null,
						type_monte: {}
					})
				}
			}
		},
		components: {
			SeasonInput : () => import('@/components/Inputs/SeasonInput'),
			SearchJument : () => import('@/components/Contract/SearchJument'),
			Etalonv2 : () => import('@/components/Contract/Type/Etalonv2'),
			Modelev2 : () => import('@/components/Contract/Type/Modelev2'),
			TypeMontev2 : () => import('@/components/Contract/Type/TypeMontev2'),
			ChangementCMEPv2 : () => import('@/components/Contract/AvenantType/ChangementCMEPv2'),
		}
	}

</script>